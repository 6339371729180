import store from '@/state/store'

export default [
  {
    path: '',
    name: 'home',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/chat/index')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      beforeResolve (routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      }
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/pages/account/register'),
    meta: {
      beforeResolve (routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
      beforeResolve (routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      }
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve (routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
          store.dispatch('auth/logOut')
        } else {
          store.dispatch('authfack/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
              name: 'home'
            }
            : {
              ...routeFrom
            }
        )
      }
    }
  },
  {
    path: '/lock-screen',
    name: 'lock-screen',
    component: () => import('../views/pages/account/lock-screen')
  },
  {
    path: '/unichat',
    name: 'unichat',
    component: () => import('../views/pages/unichat/index')
  },
  {
    path: '/enterprise-code',
    name: 'enterprise-code',
    component: () => import('../views/pages/enterprise-code/index')
  },
  {
    path: '/qywx',
    name: 'qywx',
    component: () => import('../views/pages/account/qywx')
  },
  {
    path: '/inspection-report',
    name: 'inspection-report',
    component: () => import('../views/pages/chat/inspection-report')
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('../views/pages/layout/index'),
    children: [
      {
        path: 'layouthome',
        name: 'layouthome',
        component: () => import('../views/pages/layout/home'),
        meta: {
          name: '首页',
          active: 'ri-home-wifi-line',
          inactive: 'ri-home-wifi-fill'
        }
      }, {
        path: 'market',
        name: 'market',
        component: () => import('../views/pages/layout/market'),
        meta: {
          name: '模版市场',
          active: 'ri-layout-masonry-line',
          inactive: 'ri-layout-masonry-fill'
        }
      }, {
        path: 'aichat',
        name: 'aichat',
        component: () => import('../views/pages/layout/aichat'),
        meta: {
          name: 'Ai客服机器人',
          active: 'ri-chat-1-line',
          inactive: 'ri-chat-1-fill'
        }
      }, {
        path: 'repository',
        name: 'repository',
        component: () => import('../views/pages/layout/repository'),
        meta: {
          name: '智能知识库',
          active: 'ri-profile-line',
          inactive: 'ri-profile-fill'
        }
      }, {
        path: 'property',
        name: 'property',
        component: () => import('../views/pages/layout/property'),
        meta: {
          name: '资产中心',
          active: 'ri-cloud-line',
          inactive: 'ri-cloud-fill'
        }
      },
    ]
  },
  {
    path: '/layoutFoot',
    name: 'layoutFoot',
    component: () => import('../views/pages/layout/index'),
    children: [
      {
        path: 'quotation',
        name: 'quotation',
        component: () => import('../views/pages/layout/quotation'),
        meta: {
          name: '报价',
          active: 'ri-money-cny-circle-line',
          inactive: 'ri-money-cny-circle-fill'
        }
      }, {
        path: 'tutorial',
        name: 'tutorial',
        component: () => import('../views/pages/layout/tutorial'),
        meta: {
          name: '教程',
          active: 'ri-bookmark-line',
          inactive: 'ri-bookmark-fill'
        }
      }, {
        path: 'notice',
        name: 'notice',
        component: () => import('../views/pages/layout/notice'),
        meta: {
          name: '公告',
          active: 'ri-mail-line',
          inactive: 'ri-mail-fill'
        }
      }
    ]
  },
  {
    path: '/layoutMid',
    name: 'layoutMid',
    component: () => import('../views/pages/layout/index'),
    children: [
      {
        path: 'avatar',
        name: 'avatar',
        component: () => import('../views/pages/layout/avatar/index'),
        meta: {
          name: '创建数字人'
        }
      }
    ]
  },
]
