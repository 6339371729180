import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'

import router from './router'

import '@/assets/scss/app.scss'
import i18n from './i18n'

import store from '@/state/store'
import { initFirebaseBackend } from './authUtils'

import { configureFakeBackend } from './helpers/fake-backend'
import VueClipboard from 'vue-clipboard2'
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.use(VueClipboard)
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
}

if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
  initFirebaseBackend(firebaseConfig)
} else if (process.env.VUE_APP_DEFAULT_AUTH === 'fakebackend') {
  // setup fake backend
  configureFakeBackend()
}

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()

Vue.use(BootstrapVue)
Vue.use(Vuelidate)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

Vue.directive('animate', {
  inserted: function (el, binding) {
    // 聚焦元素
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect()
      const h =
        document.documentElement.clientHeight || document.body.clientHeight
      if (top < h) {
        if (el.className.indexOf(binding.value) == -1) {
          // 下面单引号中间是一个空格
          el.className = binding.value + ' ' + el.className
        }
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass)
        }
      }
    }
    window.addEventListener('scroll', binding.addClass, true)
    binding.addClass()
  },
  unbind: function (el, binding) {
    if (binding.addClass) {
      window.removeEventListener('scroll', binding.addClass)
    }
  }
})
