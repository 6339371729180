
import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 15000, // request timeout
})

service.interceptors.request.use(
  config => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
      config.headers['X-Access-Token'] = user.token;
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    console.log('接口信息报错' + error)
    if (error.response.status === 401) {
      localStorage.clear();
      location.replace('/login')
    }
    return Promise.reject(error)
  },
)

export default service

