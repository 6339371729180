import serve from "./../axios/axios"
const api = {
    tutorialVideo: "kb-api/tutorialVideo/list", // 数字人视频教程列表
    tagsAvatar: "kb-api/kbCommon/tags/avatar", // 数字人模版的标签列表
    avatarModel: "kb-api/avatarModel/list/public", // 数字人模版的列表
    avatar: 'kb-api/avatar/list/template',//数字人模板列表
    property: 'kb-api/avatar/list',//资产中心数字人列表
    stats: 'kb-api/avatar/stats/get',//数字人的统计信息
    tagsRobot: "kb-api/kbCommon/tags/robot", // 机器人模版的标签列表
    robotModel: "kb-api/robotModel/list/public", // 机器人模版的列表
    depart: 'kb-api/kbCommon/depart/list',//用户工作空间的机构列表
}

const request = {
    // 查询列表
    tutorialVideoList: (params) => {
        let congfig = {
            url: api.tutorialVideo,
            params: params,
            method: "GET"
        }
        return serve(congfig)
    },
    // 数字人模版的标签列表
    tagsAvatarList: (params) => {
        let congfig = {
            url: api.tagsAvatar,
            params: params,
            method: "GET"
        }
        return serve(congfig)
    },
    // 数字人模版的列表
    avatarList: (params) => {
        let congfig = {
            url: api.avatar,
            params: params,
            method: "GET"
        }
        return serve(congfig)
    },
    // 资产中心数字人模版的列表
    propertyList: (params) => {
        let congfig = {
            url: api.property,
            params: params,
            method: "GET"
        }
        return serve(congfig)
    },
    // 数字人统计信息
    statsInfo: (params) => {
        let congfig = {
            url: api.stats,
            params: params,
            method: "GET"
        }
        return serve(congfig)
    },
    // 数字人模版的列表
    avatarModelList: (params) => {
        let congfig = {
            url: api.avatarModel,
            params: params,
            method: "GET"
        }
        return serve(congfig)
    },
    // 机器人模版的标签列表
    tagsRobotList: (params) => {
        let congfig = {
            url: api.tagsRobot,
            params: params,
            method: "GET"
        }
        return serve(congfig)
    },
    // 机器人模版的列表
    robotModelList: (params) => {
        let congfig = {
            url: api.robotModel,
            params: params,
            method: "GET"
        }
        return serve(congfig)
    },
    // 查询用户工作空间的机构列表
    departList: (params) => {
        let congfig = {
            url: api.depart,
            params: params,
            method: "GET"
        }
        return serve(congfig)
    },
}

export default request