import request from '@/request/api/layout.js';

export const state = {
    departList: [],
    currentDepart: {}
};
export const mutations = {
    setDepartList(state,data) {
        state.departList = data;
    },
    setCurrentDepart(state,id) {
        let depart = state.departList.find(item => item.id === id)
        if (depart) {
            state.currentDepart = depart;
            window.localStorage.setItem('depart_current', JSON.stringify(depart))
        }
    }
};

export const actions = {
    getDepartList({ commit }) {
        request.departList({}).then((res) => {
            if (res && res.code == 200) {
                commit('setDepartList', res.result);
                commit('setCurrentDepart', res.result[0].id);
            }
        });
    },
};
