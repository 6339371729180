<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data () {
    return {
      clientWidth: null,
    };
  },
  mounted () {
    this.clientWidth = document.documentElement.clientWidth
    window.onresize = () => {
      return (() => {
        this.clientWidth = document.documentElement.clientWidth
      })()
    }
  },
  watch: {
    clientWidth: {
      handler: function (val) {
        if (val < 570) {
          this.$store.commit('auth/updatePageType', 'phone')
        } else {
          this.$store.commit('auth/updatePageType', 'pc')
        }
      },
    },
  }
}
</script>
